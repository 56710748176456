<template>
   <div class="notice_popup human">
        <div class="close">
            <img src="/images/close.png" alt="닫기" @click="$emit('close', false)"/>
        </div>
        <div class="tit">관심인재 {{this.type}}</div>
        <tec-summary tagNm="div" class="member" v-model="data" :tecMberSeq="param.tecMberSeq" interestYn="Y" @input="searchEnd"/>
        <template v-if="data.mberNm">
            <div class="member" v-if="param.regYn == 'Y'">위 기술인재를 '관심인재'로 등록하시겠습니까?</div>
            <div class="member" v-else>위 기술인재를 '관심인재'에서 삭제하시겠습니까?</div>
            <div class="select-btn">
                <div class="btn" @click="$emit('close', false)">취소</div>
                <div class="btn" @click="save">예</div>
            </div>
        </template>
    </div>
</template>

<script>
import tecSummary from "@/components/highpro/TecSummary.vue";
export default {
    components: { tecSummary },
    props: ['param'],
    data() {
        return {
            data : {},
            type : this.param.regYn == 'Y' ? '등록' : '삭제',
        };
    }, 
    methods: {
        searchEnd(data){
            this.data = data;
            if(this.param.regYn == this.data.interestYn){
                this.$nextTick(()=>{
                    if(this.param.regYn == 'Y'){
                        alert('이미 등록 된 회원입니다.');
                    } else {
                        alert('이미 삭제 된 회원입니다.')
                    }
                    this.$emit('close', this.param.regYn);
                });
            }
        },
        save(){
            var param = {}
            param.tecMberSeq = this.param.tecMberSeq;
            param.regYn = this.param.regYn;
            this.$.httpPost('/api/mem/srh/setInterestYn', param)
                .then(() => {
                    if(this.param.regYn == 'Y'){
                        alert('관심인재 등록을 완료하였습니다.');
                    } else {
                        alert('관심인재 삭제를 완료하였습니다.')
                    }
                    this.$emit('close', this.param.regYn);
                });
        }
    },
};
</script>